import { LARGE_PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProductImage/Base';

export const LARGE_PRODUCT_BASE_FRAGMENT = `
  fragment largeProductBaseFragment on LargeProduct {
    uid
    schemaCode
    name
    description
    featured
    url
    videoUrl
    dimensions
    manufacturer
    model
    serie
    serialNumber
    price
    publicationTime
    endPublicationTime
    images(orderBy: [id_asc]) {
      ...largeProductImageBaseFragment
    }
  }
  ${LARGE_PRODUCT_IMAGE_BASE_FRAGMENT}
`;
